'use strict';

angular.module('webPartnerPortalApp')
  .controller('UsersCtrl', ['$scope', 'roles', 'users', '$http', '$modal', function ($scope, roles, users, $http, $modal) {
    var UsersCtrlVM = this;

    UsersCtrlVM.roles = {};
    angular.forEach(roles.data, function(role) {
      UsersCtrlVM.roles[role.portal_user_role_id] = role.role_name;
    });
    UsersCtrlVM.users = users.data.result;
    function setPaginationValues (users, offset) {
      UsersCtrlVM.offset = offset || 0;
      UsersCtrlVM.resultCount = users.result_count;
      UsersCtrlVM.totalCount = users.total_count;
    }
    setPaginationValues(users.data);
    // Open Modal Method
    UsersCtrlVM.dateFilter = function (serverDate) {
      return new Date(serverDate);
    };
    UsersCtrlVM.openModal = function (user) {
      var modalInstance = $modal.open({
        templateUrl: 'newUserModal.html',
        controller: 'UserModalCtrl',
        controllerAs: 'vm',
        windowClass: 'modal',
        backdrop: 'static',
        resolve: {
          user: function () {
            return user || {};
          },
          newUser: function (){
            return user ? false : true;
          },
          roles: function() {
            var troles = [];
            angular.forEach(roles.data, function(role) {
              if(role.status){
                troles.push(role);
              }
            });
            return troles;
          }
        }
      });
      modalInstance.result.then(function() {
        $http.get('/api/user')
          .success(function(data){
            UsersCtrlVM.users = data.result;
            setPaginationValues(data);
          });
      }, function () {
        console.log('error');
      });
    };
    UsersCtrlVM.changePassword = function (user) {
      var modalInstance = $modal.open({
        templateUrl: 'changePassword.html',
        controller: 'PasswordModalCtrl',
        controllerAs: 'vm',
        windowClass: 'modal',
        backdrop: 'static',
        resolve: {
          user: function () {
            return user;
          }
        }
      });
      modalInstance.result.then(function() {
        console.log('password changed');
      });
    };
  }]);
